import {applyNowUrl} from "../constants";
import {REST} from "../rest";


interface applyNowMultiAppValidationProps {
    email?: string;
    listing_id?: string;
}

interface applyNowExistingApplicationProps {
    email?: string;
    applicationId?: string;
}

//RB-3677:  Apply Now Mutliple Application Validation API
export const applyNowMultiAppValidationApi = {
    get: async ({ email, listing_id }: applyNowMultiAppValidationProps) => {
        return await REST.get(`${applyNowUrl}/renter/evaluate/${email}/${listing_id}`,
            {
                headers: {
                    'Accept': 'application/json',
                }
            }
        ).then(res => {
            return res.data
        })
    }
};

export const applyNowExistingApplicationApi = {
    post: async ({ email, applicationId }: applyNowExistingApplicationProps) => {
        const formData = new FormData();
        formData.append('email', email);
        formData.append('applicationId', applicationId);

        return await REST.post(`${applyNowUrl}/renter/notification/existing_application/${email}/${applicationId}`, {
            headers: {
                'Accept': 'application/json',
            }
        }).then(res => {
            return res.data
        })
    }
};