import React from 'react'
import { DialogTitle, IconButton, DialogContent, DialogActions } from '@material-ui/core';


const MultipleApplicationValidationModal = ({ onShowMultiAppModal }) => {
	return (
		<>
			<DialogTitle>
        <div style={{ display: 'flex', flexDirection: 'column', position: 'relative', alignItems: 'center', gap: 5, padding: '20px 10px 0px' }}>
          <div style={{ flex: 2 }}>
            <p style={{ fontSize: 20, color: '#0f6cf6', fontWeight: 'bold' }}>
              Application Exists!
            </p>
          </div>
        </div>
        <hr style={{ margin: '10px 0px 5px', border: 0, borderTop: '5px solid #eeeeee' }} />
      </DialogTitle>
      <DialogContent>
				<div style={{ padding: '0px 15px' }}>
          <div 
            style={{
              fontWeight: 'bold',
              fontSize: 'larger'
            }}
          >
            Please Note:
          </div>
          <div
            style={{
              margin: '10px 0px',
              fontSize: 'medium',
              lineHeight: 3
            }}
          >
            The tenant already has an active application for this listing. Click <b>'View'</b> below to access it.
          </div>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: '20px 38px 50px', justifyContent: 'space-between' }}>
        <button onClick={() => window.location.href = '/broker_portal/applications'} className='invite-applicant-btn' style={{ width: '45%' }}>View</button>
        <button onClick={() => onShowMultiAppModal(false)} className='invite-applicant-btn' style={{ width: '45%', background: '#EA3336' }}>Cancel</button>
      </DialogActions>
		</>
	)
}

export default MultipleApplicationValidationModal;